function updateCartCountBadge() {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    let totalCount = 0;

    cart.forEach(product => {
        totalCount += parseInt(product.quantity, 10);
    });

    const badgeElement = document.getElementById('cart-count');
    badgeElement.textContent = totalCount;

    // If cart is empty, hide the badge. Otherwise, display it.
    if (totalCount === 0) {
        badgeElement.style.display = 'none';
    } else {
        badgeElement.style.display = 'inline';
    }
}


export function displayCart() {
    // Get the current cart from storage
    var cart = JSON.parse(localStorage.getItem('cart')) || [];

    const cartContainer = document.querySelector('.cartItems');
    // Clear current cart display
    try {
        cartContainer.innerHTML = '';

    
    // Generate new cart display
    cart.forEach((product, index) => {
        const { id, title, price, imagesrc, quantity, html } = product;
        const itemHTML = `
            <li class="mt-4" data-id="${id}">
            <div class="row g-2 g-lg-3 align-items-center">
            <a href="${html}" class="col-3 col-md-2"><img class="img-fluid" src="${imagesrc}" alt="Product"></a>
                <div class="col">
                <a href="" class="text-black text-hover-primary lead">${title}</a>
                <ul class="list-inline text-muted">
                    <li class="list-inline-item">Price: <span class="text-secondary">${price}</span></li>
                    <li class="list-inline-item">Qty:
                    <div class="counter text-secondary" data-counter="qty-1">
                    <button class="btn btn-outline-secondary" data-id="${id}" type="button">-</button>
                        <input type="number" name="qty-1" class="counter-value form-control text-center" value="${quantity}" min="1" max="99">
                    <button class="btn btn-outline-secondary" data-id="${id}" type="button">+</button>
                    </div>
                    </li>
                </ul>
                <a href="" class="text-red underline remove-item" data-index="${index}">Remove</a>
                </div>
            </div>
            </li>
        `;
        cartContainer.insertAdjacentHTML('beforeend', itemHTML);

        // Add event listeners for quantity modification buttons
        const minusBtn = cartContainer.querySelector(`button[data-id="${id}"]:nth-child(1)`);
        minusBtn.addEventListener('click', handleMinusClick);

        const plusBtn = cartContainer.querySelector(`button[data-id="${id}"]:nth-child(3)`);
        plusBtn.addEventListener('click', handlePlusClick);
    });

    // Add remove item functionality
    const removeButtons = document.querySelectorAll('.remove-item');
    removeButtons.forEach(button => {
      button.addEventListener('click', function(event) {
        event.preventDefault();
        // Remove the product from the cart
        const index = button.getAttribute('data-index');
        removeItemFromCart(index);
      });
    });
    updateTotalPrice(); // update total price after displaying cart
    updateCartCountBadge();
} catch (error) {
}
}

function removeItemFromCart(index) {
    // Get the current cart from storage
    var cart = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Remove the product at the specified index
    cart.splice(index, 1);
  
    // Save the updated cart back to storage
    localStorage.setItem('cart', JSON.stringify(cart));
  
    // Update the display
    displayCart();
    updateCartCountBadge();
}

function handleMinusClick() {
    const qtyInput = this.parentNode.querySelector('.counter-value');
    const id = Number(this.getAttribute('data-id'));
    
    if (qtyInput.value > 1) {
      qtyInput.value--;
      updateCartQuantity(id, qtyInput.value);
    }
}

function handlePlusClick() {
    const qtyInput = this.parentNode.querySelector('.counter-value');
    const id = Number(this.getAttribute('data-id'));
    
    if (qtyInput.value < 1000) {
      qtyInput.value++;
      updateCartQuantity(id, qtyInput.value);
    }
}

function updateCartQuantity(id, quantity) {
    // Get the cart from localStorage
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Find the item in the cart
    const itemIndex = cart.findIndex(item => item.id === id);
    
    if (itemIndex !== -1) {
      // Update the quantity of the item
      cart[itemIndex].quantity = quantity;
      // Save the updated cart back into localStorage
      localStorage.setItem('cart', JSON.stringify(cart));
    }
    updateTotalPrice();
    updateCartCountBadge();
}

export function updateTotalPrice() {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    let subtotal = 0;

    cart.forEach(item => {
        subtotal += item.price * item.quantity;
    });

    const delivery = 0; // free delivery
    const tax = 0; 
    const insurance = 0; // static insurance charge

    const totalPrice = subtotal + delivery + tax + insurance;

    document.getElementById('totalPrice').innerText = `$${totalPrice.toFixed(2)}`;
}
// Call displayCart when the page loads
document.addEventListener('DOMContentLoaded', displayCart);
