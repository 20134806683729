function placeOrder() {
  const name = document.getElementById("inputName").value;
  const company = document.getElementById("inputCompany").value;
  const country = document.getElementById("inputCountry").value;
  const address = document.getElementById("inputAddress").value;
  const city = document.getElementById("inputCity").value;
  const zip = document.getElementById("inputZip").value;
  const stateProvince = document.getElementById("inputStateProvince").value;
  const phoneNumber = document.getElementById("inputPhoneNumber").value;
  const email = document.getElementById("inputEmail").value;
  const notes = document.getElementById("inputNotes").value;

  // Generate cart summary
  var cart = JSON.parse(localStorage.getItem('cart')) || [];
  var cartSummary = '';

  cart.forEach((product, index) => {
    const { title, price, quantity } = product;
    const totalProductPrice = price * quantity; // total price for the product

    const itemHTML = `
      ${title} x${quantity} - $${totalProductPrice.toFixed(2)}
    `;
    cartSummary += itemHTML + '<br>';
  });

  Email.send({
    SecureToken : "b10ce876-afb0-41e8-9c97-77b407af3bda",
    To : 'contact@aihomeinc.com',
    From : "website@aihomeinc.com",
    Subject : `New Inquiry from ${name} at ${company}`,
    Body : `Hello, <br><br>You have received a new inquiry from the following individual: <br><br>Name: ${name}<br>Company: ${company}<br>Country: ${country}<br>Address: ${address}<br>City: ${city}<br>Zip Code: ${zip}<br>State/Province: ${stateProvince}<br>Phone Number: ${phoneNumber}<br>Email: ${email}<br>Notes: ${notes}<br><br>Best regards,<br>Your Website`,
  }).then((message) => {
    console.log(message);
    document.getElementById('emailSuccessAlert').classList.remove('d-none');
  });
  
  }
  document.addEventListener('DOMContentLoaded', (event) => {
    try{
    const placeOrderBtn = document.getElementById("placeOrderBtn");
    placeOrderBtn.addEventListener("click", function(event) {
      event.preventDefault();
      placeOrder();
    });      
  }catch(error){}
  });

  