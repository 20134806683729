import { products } from './products.js';


function updateCartCountBadge() {
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  let totalCount = 0;

  cart.forEach(product => {
      totalCount += parseInt(product.quantity, 10);
  });

  const badgeElement = document.getElementById('cart-count');
  badgeElement.textContent = totalCount;

      badgeElement.style.display = 'inline';
  
}

document.addEventListener('DOMContentLoaded', () => {
  updateCartCountBadge();
  // Add event listeners to each "Add to Cart" button
  const addToCartButtons = document.querySelectorAll('.product-add-to-cart');
  addToCartButtons.forEach(button => {
    button.addEventListener('click', function() {
      // Add the product to the cart
      const item = button.parentElement; // Get the parent item of the button
      const productId = Number(item.getAttribute('id')); // Assuming the productId is stored as a data attribute on the item
      const product = products.find(product => product.id === productId); // Find the corresponding product
      // If product is not found, return early to avoid errors
      if (!product) return;

      const title = product.title;
      const price = product.price;
      const imagesrc = product.image.length > 0 ? product.image[0] : ''; // Get the first image URL
      const html = product.html;

      productAddToCart(title, price, imagesrc, productId, html);
    });
  });  

});

function productAddToCart(title, price, imagesrc, productId, html) {
  // Get the current cart from storage
  var cart = JSON.parse(localStorage.getItem('cart')) || [];

  // Check if the product is already in the cart
  const existingProductIndex = cart.findIndex(product => product.id === productId);

  if (existingProductIndex >= 0) {
    // Product exists, ensure quantity is an integer then increment
    cart[existingProductIndex].quantity = parseInt(cart[existingProductIndex].quantity) + 1;
  } else {
      // Add new product to the cart with quantity 1
      const product = { id: productId, title, price, imagesrc, quantity: 1, html };
      cart.push(product);
  }


  // Save the updated cart back to storage
  localStorage.setItem('cart', JSON.stringify(cart));
  updateCartCountBadge();

  // Create a Bootstrap alert
  const alertDiv = document.createElement('div');
  alertDiv.className = 'alert alert-success alert-dismissible fade show'; // Add necessary Bootstrap classes
  alertDiv.setAttribute('role', 'alert');
  alertDiv.innerHTML = `
      <strong>Success!</strong> ${title} has been added to your cart.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  `;

  // Append the alert to a container (e.g., a div with a specific ID)
  const alertContainer = document.getElementById('alert-container'); // Ensure you have a container with this ID in your HTML
  alertContainer.appendChild(alertDiv);

  // Remove the alert after a set duration (e.g., 3 seconds)
  setTimeout(() => {
      alertDiv.remove();
  }, 3000);
}
