import { updateTotalPrice } from './cart.js';

export function displaySummaryCart() {
    // Get the current cart from storage
    var cart = JSON.parse(localStorage.getItem('cart')) || [];
    const cartContainer = document.querySelector('.list-group.list-group-minimal.cart-summary');
    // Clear current cart display
    try{
    cartContainer.innerHTML = '';

    // Generate new cart display
    cart.forEach((product, index) => {
        const { id, title, price, imagesrc, quantity } = product;
        const totalProductPrice = price * quantity; // total price for the product

        const itemHTML = `
            <li class="list-group-item d-flex justify-content-between align-items-start text-black">
                <span>
                    ${title} x${quantity}
                </span>
                $${totalProductPrice.toFixed(2)}
            </li>
        `;
        cartContainer.insertAdjacentHTML('beforeend', itemHTML);
    });
    updateTotalPrice();
} catch (error) {
}

}
document.addEventListener('DOMContentLoaded', displaySummaryCart);
