import { products } from './products.js';
import { displayCart } from './cart.js';
import noUiSlider from 'nouislider';

function createProductHTML(product) {
  return `
  <div class="col-md-6 col-xl-4">
  <style>
      .product {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }
  </style>

  <div class="product" id=${product.id}>
      <a href="${product.html}">
          <figure class="product-image" style="width: 300px; height: 300px; overflow: hidden; position: relative;">
              ${product.image.map(imgSrc => `<img src="${imgSrc}" alt="${product.title}" />`).join('')}
          </figure>
      </a>
      <h4 class="product-title">${product.title}</h4>
      <p class="product-price">$${product.price}</p>
      <button class="btn btn-dark-red btn-sm  rounded-pill add-to-cart" data-id="${product.id}">
          <strong>Add to Cart</strong>
      </button>
  </div>
</div>
  `;
}

function displayProducts(sortOption, priceRange, categories) {
  const productContainer = document.getElementById('product-list');
  productContainer.innerHTML = '';  // Clear the container

  // Decide the sort function based on the chosen sort option
  let sortFunction;
  switch (sortOption) {
    case 'Price high to low':
      sortFunction = (a, b) => parseFloat(b.price) - parseFloat(a.price);
      break;
    case 'Price low to high':
      sortFunction = (a, b) => parseFloat(a.price) - parseFloat(b.price);
      break;
    default:
      sortFunction = null;
  }

  // Sort the products if a sort function is chosen
  let displayedProducts = [...products];  // Create a copy of the products array
  if (sortFunction) {
    displayedProducts.sort(sortFunction);
  }


  // Filter the products based on the selected price range
  displayedProducts = displayedProducts.filter(product => {
    let price = parseFloat(product.price);
    if (categories.length === 0) {
      return price >= priceRange[0] && price <= priceRange[1];
    } else {
      return price >= priceRange[0] && price <= priceRange[1] && categories.includes(product.category);
    }
  });

  // Create the HTML for each product and add it to the container
  displayedProducts.forEach(product => {
    const productHTML = createProductHTML(product);
    productContainer.insertAdjacentHTML('beforeend', productHTML);
  });

  // Add event listeners to each "Add to Cart" button
  const addToCartButtons = document.querySelectorAll('.add-to-cart');
  addToCartButtons.forEach(button => {
    button.addEventListener('click', function() {
      // Add the product to the cart
      const item = button.parentElement; // Get the parent item of the button
      const productId = Number(item.getAttribute('id')); // Assuming the productId is stored as a data attribute on the item
      const product = products.find(product => product.id === productId); // Find the corresponding product
      // If product is not found, return early to avoid errors
      if (!product) return;

      const title = product.title;
      const price = product.price;
      const imagesrc = product.image.length > 0 ? product.image[0] : ''; // Get the first image URL
      const html = product.html;
      addToCart(title, price, imagesrc, productId, html);
    });
  });
}

function updateCartCountBadge() {
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  let totalCount = 0;

  cart.forEach(product => {
      totalCount += parseInt(product.quantity, 10);
  });

  const badgeElement = document.getElementById('cart-count');
  badgeElement.textContent = totalCount;

      badgeElement.style.display = 'inline';
  
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.href.includes('shop-listing-sidebar.html') || window.location.href.includes('korean-shop.html')){
  updateCartCountBadge();

  let checkedCategories = [];

  function updateCheckedCategories() {
    try{
      // Clear the array
      checkedCategories = [];

      // Populate the array with values of checked checkboxes
      document.querySelectorAll(".form-check-input:checked").forEach(input => {
          checkedCategories.push(input.value);
      });

      // Re-display products based on updated categories and current price range
      displayProducts(null, priceSlider.noUiSlider.get().map(value => parseInt(value)), checkedCategories);
    }catch(error){}
  }
 
  // Attach event listeners to the checkboxes

  document.querySelectorAll(".form-check-input").forEach(input => {
      input.addEventListener('change', updateCheckedCategories);
  });

  let priceSlider = document.getElementById('price-slider');
  let minPriceOutput = document.getElementById('range-min');
  let maxPriceOutput = document.getElementById('range-max');

  // Initialize the slider with the noUiSlider library
 
  noUiSlider.create(priceSlider, {
    start: [0, 100000],
    connect: true,
    range: {
      'min': 0,
      'max': 100000
    }
  });

  // When the slider value changes, update the displayed price range and re-display the products
  priceSlider.noUiSlider.on('update', function(values, handle) {
    minPriceOutput.innerHTML = parseInt(values[0]);
    maxPriceOutput.innerHTML = parseInt(values[1]);
    displayProducts(null, values.map(value => parseInt(value)), checkedCategories);
  });


  // Display products in the default order and initial price range
  let initialPriceRange = [0, 100000];
  displayProducts(null, initialPriceRange, checkedCategories);

  // Add event listeners to sort options
  const sortOptions = document.querySelectorAll('.dropdown-item');
  sortOptions.forEach(option => {
    option.addEventListener('click', function() {
        displayProducts(this.textContent, priceSlider.noUiSlider.get().map(value => parseInt(value)), checkedCategories);
    });
  });
  }
});

function addToCart(title, price, imagesrc, productId, html) {
  // Get the current cart from storage
  var cart = JSON.parse(localStorage.getItem('cart')) || [];

  // Check if the product is already in the cart
  const existingProductIndex = cart.findIndex(product => product.id === productId);

  if (existingProductIndex >= 0) {
    // Product exists, increment quantity
    cart[existingProductIndex].quantity = parseInt(cart[existingProductIndex].quantity) + 1;
  } else {
    // Add new product to the cart with quantity 1
    const product = { id: productId, title, price, imagesrc, quantity: 1, html };
    cart.push(product);
  }

  // Save the updated cart back to storage
  localStorage.setItem('cart', JSON.stringify(cart));
  updateCartCountBadge();
  
  
  
  // Create a Bootstrap alert
  const alertDiv = document.createElement('div');
  alertDiv.className = 'alert alert-success alert-dismissible fade show alert-heading'; // Add necessary Bootstrap classes
  alertDiv.setAttribute('role', 'alert');
  alertDiv.innerHTML = `
      <strong>Success!</strong> ${title} has been added to your cart.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  `;

  // Append the alert to a container (e.g., a div with a specific ID)
  const alertContainer = document.getElementById('alert-container'); // Ensure you have a container with this ID in your HTML
  alertContainer.appendChild(alertDiv);

  // Remove the alert after a set duration (e.g., 3 seconds)
  setTimeout(() => {
      alertDiv.remove();
  }, 3000);



  // Update the display
  try {
    displayCart();
  }
  catch(err) {
  }
}
