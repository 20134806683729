import { tns } from 'tiny-slider/src/tiny-slider';

if (window.location.pathname === '/' || window.location.pathname === '/index.html' || window.location.pathname === '/korean-index.html') {
    const carousels = document.querySelectorAll('[data-carousel]');

    carousels.forEach((carousel) => {
        const settings = JSON.parse(carousel.dataset.carousel);

        settings.container = carousel;
        settings.mouseDrag = true;
        settings.controlsText = [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>'
        ];
        settings.autoplay = true; // this line to enable autoplay
        settings.autoplayTimeout = 5000; // this line to set delay between slides, in milliseconds
        settings.mode = 'gallery';
        //   settings.animateIn = 'jello'; // choose any animation from animate.css library or define your own animation
        //   settings.animateOut = 'fadeOutDown';
        settings.speed = 5000; 
        settings.loop = true;
        settings.autoplayButtonOutput = false;
        const slider = tns(settings);
        
        const updateSlidesZoom = () => {
            const slides = Array.from(slider.getInfo().slideItems);
            slides.forEach((slide, index) => {
                const background = slide.querySelector('.background');
                if (index === slider.getInfo().index) {
                    background.classList.add('zoomin');
                } else {
                    background.classList.remove('zoomin');
                }
            });
        };
        

        slider.events.on('indexChanged', updateSlidesZoom);

        // Make sure we run it once to catch the initial state
        updateSlidesZoom();
    });
}
else{
    const carousel = document.querySelectorAll('[data-carousel]');

    carousel.forEach(function (element) {
        const settings = JSON.parse(element.dataset.carousel);

        settings.container = element;
        settings.controlsText = [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>'
        ];

        tns(settings);
    });

}

const productCarousel = document.querySelectorAll('.product-slide[data-carousel]');

productCarousel.forEach(function (element) {
    const settings = JSON.parse(element.dataset.carousel);

    settings.container = element;
    settings.controlsText = [
        '<i class="bi bi-arrow-left"></i>',
        '<i class="bi bi-arrow-right"></i>'
    ];

    tns(settings);
});
