export const products = [
    {
      id: 101,
      title: 'LED Worship A',
      price: '11499',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led1.png'
      ]
    },
    {
      id: 102,
      title: 'LED Worship B',
      price: '12499',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led2.png'
      ]
    },
    {
      id: 103,
      title: 'LED Worship C',
      price: '16499',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led3.png'
      ]
    },
    {
      id: 104,
      title: 'LED Worship D',
      price: '22999',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led4.png'
      ]
    },
    {
      id: 105,
      title: 'LED Worship E',
      price: '28999',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led5.png'
      ]
    },
    {
      id: 106,
      title: 'LED Worship F',
      price: '51999',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led6.png'
      ]
    },
    {
      id: 107,
      title: 'LED Worship G',
      price: '53999',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led7.png'
      ]
    },
    {
      id: 108,
      title: 'LED Worship H',
      price: '84999',
      category: 'solution',
      html:'led-church-solution.html',
      image: [
        'assets/images/solution/church/led8.png'
      ]
    },
    {
      id: 111,
      title: 'Video System A',
      price: '2799',
      category: 'solution',
      html:'video-church-solution.html',
      image: [
        'assets/images/solution/church/video1.png'
      ]
    },
    {
      id: 112,
      title: 'Video System B',
      price: '28299',
      category: 'solution',
      html:'video-church-solution.html',
      image: [
        'assets/images/solution/church/video2.png'
      ]
    },
    {
      id: 113,
      title: 'Video System C',
      price: '35799',
      category: 'solution',
      html:'video-church-solution.html',
      image: [
        'assets/images/solution/church/video3.png'
      ]
    },
    {
      id: 121,
      title: 'LED Store A',
      price: '10999',
      category: 'solution',
      html:'led-store-solution.html',
      image: [
        'assets/images/solution/store/led1.png'
      ]
    },
    {
      id: 122,
      title: 'LED Store B',
      price: '12999',
      category: 'solution',
      html:'led-store-solution.html',
      image: [
        'assets/images/solution/store/led2.png'
      ]
    },
    {
      id: 123,
      title: 'LED Store C',
      price: '15999',
      category: 'solution',
      html:'led-store-solution.html',
      image: [
        'assets/images/solution/store/led3.png'
      ]
    },
    {
        id: 1,
        title: 'MV Pro Series',
        price: '0',
        category: 'indoor',
        html:'product-page/indoor/mvpro.html',
        image: [
          'assets/images/products/indoor/01MVpro/460.png'
        ]
      },
      {
        id: 2,
        title: 'Polaris Series',
        price: '0',
        category: 'indoor',
        html:'product-page/indoor/pl.html',
        image: [
          'assets/images/products/indoor/02PL/pl.png'
        ]
      },
      {
        id: 3,
        title: 'MV Series',
        price: '0',
        category: 'indoor',
        html:'product-page/indoor/mv.html',
        image: [
          'assets/images/products/indoor/04MV/02.png'
        ]
      },
      {
        id: 4,
        title: 'Carbon Series',
        price: '0',
        category: 'indoor',
        html:'product-page/indoor/carbon.html',
        image: [
          'assets/images/products/indoor/05CB/04.png'
        ]
      },
      {
        id: 5,
        title: 'W&WⅡ Series',
        price: '0',
        category: 'indoor',
        html:'product-page/indoor/w.html',
        image: [
          'assets/images/products/indoor/06W_WII/W.png'
        ]
      },
      {
        id: 6,
        title: 'LE&LE mini Series',
        price: '0',
        category: 'outdoor',
        html:'product-page/outdoor/le.html',
        image: [
          'assets/images/products/outdoor/01LE/02.png'
        ]
      },
      {
        id: 7,
        title: 'X Pro Series',
        price: '0',
        category: 'smallpixel',
        html:'product-page/smallpixel/xpro.html',
        image: [
          'assets/images/products/smallpixel/02Xpro/02.png'
        ]
      },
      {
        id: 8,
        title: 'MT Edge Series',
        price: '0',
        category: 'creative',
        html:'product-page/creative/mt-edge.html',
        image: [
          'assets/images/products/creative/01MTedge/07.png'
        ]
      },
      {
        id: 9,
        title: 'Armor Series',
        price: '0',
        category: 'creative',
        html:'product-page/creative/ar.html',
        image: [
          'assets/images/products/creative/03AR/01.png'
        ]
      },
      {
        id: 10,
        title: 'Vanish Series',
        price: '0',
        category: 'creative',
        html:'product-page/creative/va.html',
        image: [
          'assets/images/products/creative/04VA/1.png'
        ]
      },
      {
        id: 11,
        title: 'Blade Series',
        price: '0',
        category: 'fixedinstallation',
        html:'product-page/fixedinstallation/blade.html',
        image: [
          'assets/images/products/fixedinstallation/04BD/1.png'
        ]
      },
      {
        id: 201,
        title: 'Camera 1080P',
        price: '0',
        category: 'camera',
        html:'product-page/other/camera1080.html',
        image: [
          'assets/images/products/camera/V60XL00.png'
        ]
      },
      {
        id: 202,
        title: 'Camera 4k30P',
        price: '0',
        category: 'camera',
        html:'product-page/other/camera4k30p.html',
        image: [
          'assets/images/products/camera/VX60AL1.jpg'
        ]
      },
      {
        id: 203,
        title: 'Camera 4k60P',
        price: '0',
        category: 'camera',
        html:'product-page/other/camera4k60p.html',
        image: [
          'assets/images/products/camera/VX752A1.jpg'
        ]
      },
      {
        id: 1000,
        title: 'Video Switcher',
        price: '0',
        category: 'videoswitcher',
        html:'product-page/other/switcher.html',
        image: [
          'assets/images/products/switcher/2.png'
        ]
      },

    // ...more products...
  ];
  